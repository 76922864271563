/**
 *
 * Favorite Model
 * @author Chad Watson
 *
 *
 */
import { OrderedMap, Record, Seq } from "immutable";
import { STATUSES as BARRIER_OPERATOR_STATUSES } from "models/barrierOperator";
import { STATUSES as LOCK_STATUSES, STATUSES } from "models/lock";
import {
  defaultCoolingPoint,
  defaultHeatingPoint,
  FAN_MODES as THERMOSTAT_FAN_MODES,
  MODES as THERMOSTAT_MODES,
} from "models/thermostat";
import { applySpec, ascend, compose, has, map, prop } from "ramda";
import {
  immutableToArray,
  immutableToJS,
  immutableValueSeq,
  toInt,
} from "utils";
export const createLightFavoriteAction = Record({
  id: NaN,
  node_number: 0,
  included: true,
  level: 0,
});
export const createLockFavoriteAction = Record({
  id: NaN,
  node_number: 0,
  included: true,
  status: LOCK_STATUSES.UNKNOWN,
});
export const createThermostatFavoriteAction = Record({
  id: NaN,
  node_number: 0,
  included: true,
  mode: THERMOSTAT_MODES.OFF,
  fan_mode: THERMOSTAT_FAN_MODES.AUTO,
  setpoint_cooling: defaultCoolingPoint,
  setpoint_heating: defaultHeatingPoint,
});
export const createBarrierOperatorFavoriteAction = Record({
  id: NaN,
  node_number: 0,
  included: true,
  status: BARRIER_OPERATOR_STATUSES.UNKNOWN,
});
const createFavoriteAction = (favorite) => {
  const settings = favorite.settings;

  if (has("level", settings)) {
    return createLightFavoriteAction({
      id: favorite?.id,
      node_number: favorite?.node_number,
      included: favorite?.included,
      level: settings?.level,
    });
  } else if (
    has("status", settings) &&
    Object.values(STATUSES).includes(settings.status)
  ) {
    return createLockFavoriteAction({
      id: favorite?.id,
      node_number: favorite?.node_number,
      included: favorite?.included,
      status: settings?.status,
    });
  } else if (has("setpoint_cooling", settings)) {
    return createThermostatFavoriteAction({
      id: favorite?.id,
      node_number: favorite?.node_number,
      included: favorite?.included,
      mode: settings.mode,
      fan_mode: settings.fan_mode,
      setpoint_cooling: settings.setpoint_cooling,
      setpoint_heating: settings.setpoint_heating,
    });
  } else {
    return createBarrierOperatorFavoriteAction({
      id: favorite?.id,
      node_number: favorite?.node_number,
      included: favorite?.included,
      status: settings?.status,
    });
  }
};

export const createFavorite = Record({
  id: NaN,
  number: 0,
  name: "",
  icon: "FAVORITE",
  actions: OrderedMap(),
  updating: false,
  updateError: false,
  activating: false,
  activateError: false,
  deleting: false,
  deleteError: false,
});
export const createFavoriteFromJson = (json) => {
  return createFavorite({
    id: json.id,
    number: toInt(json.number),
    name: json.name,
    icon: json.app_icon,
    actions: Seq(json.actions || [])
      .map(createFavoriteAction)
      .reduce(
        (acc, action) => acc.set(action?.node_number, action),
        OrderedMap()
      ),
  });
};

export default createFavoriteFromJson;

export const createFavoritesFromJson = (json) =>
  Seq(json)
    .map(createFavoriteFromJson)
    .sort(ascend(prop("name")))
    .reduce((acc, favorite) => acc.set(favorite.id, favorite), OrderedMap());

const actionToJson = ({
  id,
  node_number: nodeNumber,
  included,
  ...settings
}) => {
  return {
    id: !isNaN(id) ? id : undefined,
    node_number: nodeNumber,
    _destroy: included ? undefined : true,
    json_settings: JSON.stringify(settings),
  };
};

export const apiRequestObject = applySpec({
  name: prop("name"),
  app_icon: prop("icon"),
  actions_attributes: compose(
    immutableToArray,
    map(compose(actionToJson, immutableToJS)),
    immutableValueSeq,
    prop("actions")
  ),
});
